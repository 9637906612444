import React from 'react'
import { Link } from 'gatsby'
import Layout from './layout'
import GameGuideItem from './game_guide/game_guide_item'
import PageTitle from "../components/page_title"

function BrowseGameGuides({ pageContext }) {
  const { groupedGuides, group, page } = pageContext
  return (
    <Layout>
      <PageTitle title='遊戲指南'></PageTitle>

      {group.map(({ node }) => {
        const { title } = node.frontmatter
        return (
          <GameGuideItem key={node.id} guide={node}></GameGuideItem>
        )
      })}

      <footer className='text-center'>
        {' '}
        {groupedGuides.map((x, index) => {
          const currentPage = index + 1
          return (
            <Link
              key={index}
              to={`/game_guides/${currentPage}`}
            className={
            currentPage === page ? 'mx-1 inline-block w-5 outline outline-blue-300 bg-blue-300' :
            'mx-1 inline-block w-5 outline outline-blue-300 transition hover:bg-blue-300'}
            >
              {index + 1}
            </Link>
          )
        })}
      </footer>
    </Layout>
  )
}

export default BrowseGameGuides
